exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-templates-blog-post-template-tsx": () => import("./../../../src/templates/BlogPostTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx" */),
  "component---src-templates-project-template-tsx-content-file-path-src-projects-bytes-of-love-mdx": () => import("./../../../src/templates/ProjectTemplate.tsx?__contentFilePath=/home/runner/work/Club_Website_2/Club_Website_2/src/projects/BytesOfLove.mdx" /* webpackChunkName: "component---src-templates-project-template-tsx-content-file-path-src-projects-bytes-of-love-mdx" */),
  "component---src-templates-project-template-tsx-content-file-path-src-projects-bytes-of-love-website-mdx": () => import("./../../../src/templates/ProjectTemplate.tsx?__contentFilePath=/home/runner/work/Club_Website_2/Club_Website_2/src/projects/BytesOfLoveWebsite.mdx" /* webpackChunkName: "component---src-templates-project-template-tsx-content-file-path-src-projects-bytes-of-love-website-mdx" */),
  "component---src-templates-project-template-tsx-content-file-path-src-projects-drone-mdx": () => import("./../../../src/templates/ProjectTemplate.tsx?__contentFilePath=/home/runner/work/Club_Website_2/Club_Website_2/src/projects/Drone.mdx" /* webpackChunkName: "component---src-templates-project-template-tsx-content-file-path-src-projects-drone-mdx" */),
  "component---src-templates-project-template-tsx-content-file-path-src-projects-echologator-mdx": () => import("./../../../src/templates/ProjectTemplate.tsx?__contentFilePath=/home/runner/work/Club_Website_2/Club_Website_2/src/projects/Echologator.mdx" /* webpackChunkName: "component---src-templates-project-template-tsx-content-file-path-src-projects-echologator-mdx" */),
  "component---src-templates-project-template-tsx-content-file-path-src-projects-hide-and-seek-mdx": () => import("./../../../src/templates/ProjectTemplate.tsx?__contentFilePath=/home/runner/work/Club_Website_2/Club_Website_2/src/projects/HideAndSeek.mdx" /* webpackChunkName: "component---src-templates-project-template-tsx-content-file-path-src-projects-hide-and-seek-mdx" */),
  "component---src-templates-project-template-tsx-content-file-path-src-projects-jukebox-mdx": () => import("./../../../src/templates/ProjectTemplate.tsx?__contentFilePath=/home/runner/work/Club_Website_2/Club_Website_2/src/projects/Jukebox.mdx" /* webpackChunkName: "component---src-templates-project-template-tsx-content-file-path-src-projects-jukebox-mdx" */),
  "component---src-templates-project-template-tsx-content-file-path-src-projects-open-web-services-mdx": () => import("./../../../src/templates/ProjectTemplate.tsx?__contentFilePath=/home/runner/work/Club_Website_2/Club_Website_2/src/projects/OpenWebServices.mdx" /* webpackChunkName: "component---src-templates-project-template-tsx-content-file-path-src-projects-open-web-services-mdx" */),
  "component---src-templates-project-template-tsx-content-file-path-src-projects-terminal-monopoly-mdx": () => import("./../../../src/templates/ProjectTemplate.tsx?__contentFilePath=/home/runner/work/Club_Website_2/Club_Website_2/src/projects/TerminalMonopoly.mdx" /* webpackChunkName: "component---src-templates-project-template-tsx-content-file-path-src-projects-terminal-monopoly-mdx" */)
}

